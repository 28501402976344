import React, { ReactNode } from 'react'
import * as XLSX from 'xlsx'
import { ClimateBenchmark } from '../../api/src/types/benchmark'

interface ExcelExportButtonProps {
  data: ClimateBenchmark[]
  children: ReactNode
  classes?: string
}

const ExcelExportButton: React.FC<ExcelExportButtonProps> = ({ data, children, classes }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data)

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Data')

    XLSX.writeFile(wb, 'data.xlsx')
  }

  return (
    <button onClick={exportToExcel} className={classes}>
      {children}
    </button>
  )
}

export default ExcelExportButton
