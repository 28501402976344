import React from 'react'
import { includes } from 'lodash'

import { LegendItem, LegendContext } from '../../../components/BaseGraphs/ChartContainer'
import { Show } from '../../../components/Conditions/Show'

export const RegularLegends = [
  {
    name: 'energy',
    color: '#7530bd'
  },
  {
    name: 'refrigerants',
    color: '#ff764a'
  },
  {
    name: 'waste',
    color: '#FDC22D'
  },
  {
    name: 'water',
    color: '#03A9F4'
  }
]

export const AnnualLegend = () => {
  const ctx = React.useContext(LegendContext)
  if (!ctx) return null
  if (!ctx.series) return null

  const isSelected = (name: string) => includes(ctx.selectedSeries, name)

  return (
    <div className="LegendItems AnnualLegend">
      {RegularLegends.map(item =>
        ctx?.series?.some(serie => serie.name === item.name) ? (
          <LegendItem
            key={`legend_${item.name}`}
            text={item.name}
            color={item.color}
            selected={isSelected(item.name)}
            onItemClick={() => ctx.onItemClick(item.name)}
            setItemHighlight={ctx.setItemHighlight}
          />
        ) : null
      )}

      <Show when={ctx.series.some(item => item.name === 'goal')}>
        <div className="Separator" />
        <LegendItem text="Goal" color="#a5b4d9" selected />
        <LegendItem text="Goal ambition" color="#DFDFDF" selected />
      </Show>

      <Show when={ctx.series.some(item => item.name === 'Planned activities')}>
        <div className="Separator" />

        <LegendItem text="Planned activities" color="url(#small-green-strokes-pattern)" selected />
        <LegendItem text="Missing FY30 reduction" color="#DD2A1A" selected />
      </Show>
    </div>
  )
}
