import React, { ReactNode, useRef } from 'react'
import classNames from 'classnames'
import { setYear, getYear, endOfMonth, isSameMonth, isEqual, isBefore } from 'date-fns'

import {
  getMonthlyFootprint,
  getWeeklySales,
  getMonthlyPerception,
  getTotalFootprint,
  getPeopleImpacted,
  getGoals,
  getFoodFootprint,
  getChangeMakers,
  isCountryOrGlobal,
  getEnergyEfficiencyRolling,
  getWaterEfficiency
} from '../lib/APIClient'
import { formatAbsoluteNumber, formatRelativeNumber } from './Utils/format'
import {
  getCluster,
  getCountry,
  getGoalsForLocation,
  getLocationId,
  getLocationOrDefault,
  getLocationSelector,
  isCluster,
  isSiteId
} from './Utils/utils'
import { useSharedSelections } from '../SharedSelections'
import {
  WeeklySalesData,
  MonthlySentiments,
  MonthlySentimentsWithFY,
  PeopleImpacted,
  SiteFunction,
  YtdFootprintAndGoals,
  FoodFootprint,
  ChangeMakers,
  ApiTotalFootprint,
  SiteGoals,
  CountryGoals,
  EnergyEfficiencyRolling
} from '../../api/src/common-types'

import './SnapshotTopCards.scss'
import colours from '../Colours.module.scss'
import { InformationIndicator } from './BaseGraphs/Indicators'
import { NoDataViewSmall } from './BaseGraphs/NoDataView'
import { Tooltip } from './Tooltip'
import { TableRowKey } from './Benchmark/BenchmarkTableRow'
import { difference, every, flatMap, sumBy, compact, reverse } from 'lodash'
import { LoadingSkeleton } from './LoadingSkeleton'
import { DateFormat, lineChart } from './BaseGraphs/GraphUtil'
import { getPositivelyImpacted } from '../pages/KPIPages/SocialImpact/SocialImpactKPIPage'

import { Link } from './Link'
import { ArrowIcon } from './ArrowIcon'
import { ChartContainer, DataPoint, Serie, TooltipItem } from './BaseGraphs/ChartContainer'
import { CurrentLocation, useLocations, useDataAvailabilityContext } from '../context'
import { formatFootprintSeries } from '../pages/KPIPages/Climate/ClimateKPIPage'
import { Route } from '../routes'
import { getIngkaFinancialYear } from './Utils/dates'
import { addYears } from 'date-fns/esm'
import {
  deliveryToDatapoint,
  useCustomerDeliveryData
} from '../pages/KPIPages/CustomerDeliveries/CustomerDeliveriesKPIPage'
import _ from 'lodash'
import {
  calculateBenchmarkingRecyclingRate,
  calculateTotalNonZeroWaste
} from '../pages/KPIPages/ZeroWaste/ZeroWasteKPIPage'
import { formatEnergyEfficiencySeries } from '../pages/KPIPages/EnergyEfficiency/EnergyEfficiencyKPIPage'
import { calculatePppSalesPercentage, calculatePppSeries, getPppTooltipItemsFn } from './PPPSidebarGraphs'

export enum GoalStatus {
  NoGoal = 'NoGoal',
  OnTrack = 'OnTrack',
  NotOnTrack = 'NotOnTrack'
}

interface SnapshotTopCardsProps {
  currentLocation: CurrentLocation
  siteOrCountry: string
}

type CardType = 'zero-waste' | 'planet' | 'social-impact' | 'healthy-living'

interface SnapshotCardGeneratorProps {
  selectedCards: CardType[]
  currentLocation: CurrentLocation
  siteOrCountry: string
  goals: {
    [x: number]: SiteGoals | CountryGoals | undefined
  }
}

interface TopCardProps extends SnapshotTopCardsProps {
  footer?: ReactNode
  goals: {
    [x: number]: SiteGoals | CountryGoals | undefined
  }
}

interface WeeklySentimentsWithDate extends MonthlySentimentsWithFY {
  date: Date
}

export const useFootprint = (
  siteOrCountry: string,
  func: SiteFunction[],
  startFy: number,
  endFy: number
): [number, string, YtdFootprintAndGoals[], Date[], boolean | undefined] => {
  const [footprint, setFootprint] = React.useState<YtdFootprintAndGoals[]>([])
  const [dates, setDates] = React.useState<Date[]>([])
  const { clusters } = useLocations()

  React.useEffect(() => {
    setFootprint([])
    setDates([])

    if (startFy == null || endFy == null) {
      return
    }

    getMonthlyFootprint({
      ...getLocationSelector(siteOrCountry, getCluster(clusters, siteOrCountry)?.countryCodes),
      func,
      isOld: true
    }).then(({ data, dates }) => {
      setFootprint(data)
      setDates(dates.map(d => new Date(d)))
    })
  }, [siteOrCountry, JSON.stringify(func), startFy, endFy])

  const latestPF = footprint
    .slice()
    .sort((a, b) => new Date(b.readableDate).valueOf() - new Date(a.readableDate).valueOf())
    .find(f => f.currentFY != null)
  const footprintTonnesYtd = footprint.reduce((acc, d) => acc + (d.currentFY ?? 0), 0) / 1000

  return [
    footprintTonnesYtd,
    `tonnes co2e`,
    footprint,
    dates,
    Number.isFinite(latestPF?.goal) ? footprintTonnesYtd < (latestPF?.goal as number) / 1000 : undefined
  ]
}

const useEnergyEfficiencyRolling = (siteOrCountry: string, func: SiteFunction[]) => {
  const [latestRollingValue, setLatestRollingValue] = React.useState<number>(0)
  const [rolling, setRolling] = React.useState<EnergyEfficiencyRolling[]>([])
  const [dates, setDates] = React.useState<Date[]>()
  const [series, setSeries] = React.useState<Serie[]>([])
  const { dataAvailability } = useDataAvailabilityContext()
  const locationId = getLocationOrDefault()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024

  React.useEffect(() => {
    setLatestRollingValue(0)
    setSeries([])
    setRolling([])
    setDates(undefined)

    getEnergyEfficiencyRolling(locationId, func, currentFY).then(result => {
      const dates = result.dates.map(d => new Date(d))
      const rolling = result.data
      const series = formatEnergyEfficiencySeries(result.data, dates)
      const latestRollings = rolling?.filter(f => f.currentFY) ?? []

      setLatestRollingValue(latestRollings.length !== 0 ? latestRollings[latestRollings.length - 1].currentFY ?? 0 : 0)
      setSeries(series ?? [])
      setRolling(rolling ?? [])
      setDates(dates)
    })
  }, [JSON.stringify(func), siteOrCountry])

  return {
    energyEffRolling: rolling,
    energyEffSeries: series,
    energyEffUnit: 'kWh/m²',
    energyEffDates: dates,
    energyEffValue: latestRollingValue
  }
}

const useWaterFootprint = (siteOrCountry: string, func: SiteFunction[]) => {
  const [waterValue, setWaterValue] = React.useState<number>(0)
  const [dates, setDates] = React.useState<Date[]>()
  const [series, setSeries] = React.useState<Serie[]>([])
  const { dataAvailability } = useDataAvailabilityContext()
  const locationId = getLocationOrDefault()
  const currentFY = dataAvailability?.energyCurrentFY ?? 2024
  const [onTrack, setOnTrack] = React.useState<boolean>(false)

  React.useEffect(() => {
    setWaterValue(0)
    setSeries([])
    setDates(undefined)

    getWaterEfficiency(locationId, func, currentFY).then(result => {
      const dates = result.dates.map(d => new Date(d))
      const value = result.data
      const series = formatEnergyEfficiencySeries(
        result.data.map(f => ({ ...f, fiscalYear: f.fiscalYear - 2000 })),
        dates
      )

      const waterData = value?.filter(f => f.currentFY) ?? []

      const countryVal = !isSiteId(siteOrCountry)
        ? waterData.reduce((sum, item) => sum + (item.currentFY ?? 0), 0) / waterData.length
        : 0

      setOnTrack(
        waterData.length !== 0 ? (_.last(waterData)?.currentFY ?? 0) < (_.last(waterData)?.previousFY ?? 0) : false
      )

      setWaterValue(
        waterData.length !== 0
          ? isSiteId(siteOrCountry)
            ? waterData[waterData.length - 1].currentFY ?? 0
            : countryVal
          : 0
      )
      setSeries(series ?? [])
      setDates(dates)
    })
  }, [JSON.stringify(func), siteOrCountry])

  return {
    waterSeries: series,
    waterFootprintUnit: 'liter/visitor',
    waterDates: dates,
    waterFootprintYtd: waterValue,
    waterTrack: onTrack
  }
}

const useFoodFootprint = (
  siteOrCountry: string,
  func: SiteFunction[]
): [number, Serie[], Date[], boolean | undefined] => {
  const [series, setSeries] = React.useState<Serie[]>([])
  const [dates, setDates] = React.useState<Date[]>([])
  const [totalFootprint, setTotalFootprint] = React.useState<number>(0)
  const [goalTotalFootprintYTD, setGoalTotalFootprintYTD] = React.useState<number>()
  const locationId = getLocationOrDefault()
  const { dataAvailability } = useDataAvailabilityContext()

  React.useEffect(() => {
    setSeries([])
    setDates([])
    getFoodFootprint(locationId).then(({ data, goalTotalFootprintYTD }) => {
      const prevFiscalYear = dataAvailability?.foodPreviousFY ?? 2023
      const splitByFy = data.reduce<Record<string, FoodFootprint[]>>((acc, value) => {
        if (value.fiscalYear < prevFiscalYear) return acc

        if (acc[value.fiscalYear]) acc[value.fiscalYear].push(value)
        else acc[value.fiscalYear] = [value]
        return acc
      }, {})

      const fiscalYears = Object.keys(splitByFy).map(val => parseInt(val))

      setTotalFootprint(
        data.reduce((acc, d) => {
          if (d.fiscalYear !== Math.max(...fiscalYears)) return acc
          return acc + d.nonRedMeatBasedFootprint + d.redMeatBasedFootprint + d.plantBasedFootprint
        }, 0)
      )

      setGoalTotalFootprintYTD(goalTotalFootprintYTD)

      const _dates = splitByFy[Math.min(...fiscalYears)]?.map(d => addYears(new Date(d.readableDate), 1))
      const series = formatFoodSeries(splitByFy, _dates)

      const seriesCurr = goalTotalFootprintYTD
        ? [
            ...series,
            {
              id: 'goal',
              name: `goal`,
              color: colours.lightBlue2,
              data: _dates.map((d, index) => ({
                x: new Date(d),
                y: (goalTotalFootprintYTD * (index + 1)) / 1000
              }))
            }
          ]
        : series

      setDates(_dates)
      setSeries(seriesCurr)
    })
  }, [siteOrCountry, JSON.stringify(func), goalTotalFootprintYTD])

  return [totalFootprint, series, dates, goalTotalFootprintYTD ? totalFootprint < goalTotalFootprintYTD : undefined]
}

export const formatFoodSeries = (dataSplitByFy: Record<string, FoodFootprint[]>, dates: Date[]): Serie[] => {
  const [fiscalYearA, fiscalYearB] = Object.keys(dataSplitByFy)

  const getTotal = (value: FoodFootprint) =>
    value.redMeatBasedFootprint + value.nonRedMeatBasedFootprint + value.plantBasedFootprint

  const date = endOfMonth(new Date())

  const isASmaller = parseInt(fiscalYearA) < parseInt(fiscalYearB)

  const getYtdValues = (fiscalYear: string) => {
    let currYtd = 0
    return dataSplitByFy[fiscalYear]?.reduce<{ date: Date; value: number }[]>((acc, value) => {
      if (date.getTime() - new Date(value.readableDate).getTime() > 0) {
        currYtd += getTotal(value)
        acc.push({
          date: new Date(value.readableDate),
          value: currYtd
        })
      }
      return acc
    }, [])
  }

  const fiscalAYtd = getYtdValues(fiscalYearA)
  const fiscalBYtd = fiscalYearB && getYtdValues(fiscalYearB)

  const series: Serie[] = [
    {
      color: isASmaller ? colours.offWhite1 : colours.blue,
      data: dates.map(date => {
        return {
          x: date,
          y: fiscalAYtd.find(val => {
            return val.date.getMonth() === date.getMonth()
          })?.value as number
        }
      }),
      name: `FY${fiscalYearA.slice(-2)}`,
      ...(isASmaller && { fill: colours.grey1 })
    },
    ...(fiscalYearB
      ? [
          {
            color: isASmaller ? colours.blue : colours.offWhite1,
            data: dates.map(date => ({
              x: date,
              y: fiscalBYtd
                ? (fiscalBYtd.find(val => {
                    return val.date.getMonth() === date.getMonth()
                  })?.value as number)
                : 0
            })),
            name: `FY${fiscalYearB?.slice(-2)}`,
            ...(!isASmaller && { fill: colours.grey1 })
          }
        ]
      : [])
  ]

  return series
}

const calculatePerceptionTotal = (sentiment: MonthlySentiments | undefined) => {
  if (sentiment == null) {
    return NaN
  }
  return sentiment.positive + sentiment.neutral + sentiment.negative
}

const calculateSentimentRatio = (sentiments: MonthlySentiments[]) => {
  const sums = sentiments.reduce(
    (result, m) => ({ positive: result.positive + m.positive, total: result.total + calculatePerceptionTotal(m) }),
    { positive: 0, total: 0 }
  )
  return (sums.positive / (sums.total > 0 ? sums.total : NaN)) * 100
}

export const usePerception = (
  countryCode?: string
): [number, string, WeeklySentimentsWithDate[], WeeklySentimentsWithDate[], number] => {
  const [currentFYPerception, setCurrentFYPerception] = React.useState<WeeklySentimentsWithDate[]>([])
  const [prevFYPerception, setPrevFYPerception] = React.useState<WeeklySentimentsWithDate[]>([])

  React.useEffect(() => {
    if (!countryCode) {
      return
    }

    setCurrentFYPerception([])
    setPrevFYPerception([])

    getMonthlyPerception(countryCode).then(result => {
      const { currentFY, previousFY } = result
      if (Array.isArray(currentFY)) {
        setCurrentFYPerception(currentFY.map(s => ({ ...s, date: new Date(s.id) })))
        if (Array.isArray(previousFY)) {
          setPrevFYPerception(previousFY.slice(-currentFY.length).map(s => ({ ...s, date: new Date(s.id) })))
        }
      }
    })
  }, [countryCode])

  const now = new Date()
  const passedFY = currentFYPerception.filter(p => p.date.getTime() < now.getTime())
  const currentSentimentRatio = calculateSentimentRatio(passedFY)
  const comparisonSentimentRatio = calculateSentimentRatio(prevFYPerception.slice(0, passedFY.length))

  return [currentSentimentRatio, `% positive mentions`, currentFYPerception, prevFYPerception, comparisonSentimentRatio]
}

export const usePppSales = (
  siteOrCountry: string,
  startFy: number,
  endFy: number
): [number, string, WeeklySalesData[]] => {
  const [pppSales, setPPPSales] = React.useState<WeeklySalesData[]>([])

  React.useEffect(() => {
    setPPPSales([])

    if (startFy == null || endFy == null) {
      return
    }

    getWeeklySales(siteOrCountry, startFy, endFy, 'all').then(result => {
      setPPPSales(result)
    })
  }, [siteOrCountry, startFy, endFy])

  const latestPpp = pppSales
    .sort((a, b) => new Date(b.readableDate).getTime() - new Date(a.readableDate).getTime())
    .find(p => p.pppSales != null)
  const currentPppShare =
    (((latestPpp?.pppSalesFY ?? NaN) + (latestPpp?.asisSalesFY ?? NaN)) / (latestPpp?.totalSalesFY ?? NaN)) * 100

  return [currentPppShare, `% P+PP sales share`, pppSales]
}

const getProfitTooltipText = (isCountry: boolean) => (isCountry ? undefined : 'Year goal is defined on a country level')

export const HealthyLivingTopCard: React.FC<TopCardProps> = ({ footer, currentLocation, siteOrCountry, goals }) => {
  const { dataAvailability } = useDataAvailabilityContext()

  const isCountry = !currentLocation.isCluster && currentLocation.location.countryCode === siteOrCountry

  const [currentPppShare, currentPppShareUnit, pppSales] = usePppSales(
    siteOrCountry,
    dataAvailability?.profitPreviousFY ?? 2000,
    dataAvailability?.profitCurrentFY ?? 2000
  )

  const goal = goals[dataAvailability?.profitCurrentFY ?? 2024]?.pppShareGoal

  const seriesProfit = calculatePppSeries(
    pppSales,
    calculatePppSalesPercentage,
    colours.blue,
    colours.lightBlue2,
    true,
    goal
  )
  const domainProfit = reverse(seriesProfit[0].data.map(x => x.x))

  return (
    <SnapshotTopCard
      graphs={[
        {
          id: TableRowKey.PppSales,
          title: 'People + Planet Positive Sales',
          page: Route.PppSalesKPIPage,
          value: currentPppShare,
          unit: currentPppShareUnit,
          series: seriesProfit,
          domain: domainProfit,
          dateFormat: 'week',
          tooltipUnit: '%',
          tooltipItemsFn: getPppTooltipItemsFn(
            pppSales,
            calculatePppSalesPercentage,
            colours.blue,
            colours.lightBlue2,
            'ppp',
            true,
            goal
          ),
          onTrack:
            currentPppShare > (!currentLocation.isCluster ? currentLocation.location.profitShareGoalCurrFy * 100 : 100),
          footer: footer,
          informationText: getProfitTooltipText(isCountry),
          startFromZero: false
        }
      ]}
      label="Healthy & Sustainable Living"
      headerRoute={Route.PppSalesKPIPage}
    />
  )
}

function planetFootprintGoalAvailableForFunctions(func: SiteFunction[], locationId: string) {
  const fpGoalFuncs: SiteFunction[] =
    locationId === 'SE'
      ? ['ALL', 'Retail', 'Tenants', 'Common Areas', 'Customer Fulfillment', 'Support units']
      : ['ALL', 'Retail', 'Tenants', 'Common Areas', 'Customer Fulfillment']
  return fpGoalFuncs.some(f => func.includes(f))
}

export const PlanetTopCard: React.FC<TopCardProps> = ({ footer, siteOrCountry, currentLocation, goals }) => {
  const [{ func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()

  const [footprintTonnesYtd, footprintTonnesYtdUnit, footprint, dates, onTrack] = useFootprint(
    siteOrCountry,
    func,
    dataAvailability?.planetPreviousFY ?? 2000,
    dataAvailability?.planetCurrentFY ?? 2000
  )

  const [foodFootprintTonnesYtd, foodFootprintSeries, foodDates, onTrackFood] = useFoodFootprint(siteOrCountry, func)

  const fpGoalFuncsSelected =
    !currentLocation.isCluster && planetFootprintGoalAvailableForFunctions(func, currentLocation.location.countryCode)
  const shouldShowFPGoal = fpGoalFuncsSelected
  const series = formatFootprintSeries(footprint, dates, shouldShowFPGoal)

  const currentFYName = `FY${(dataAvailability?.planetCurrentFY ?? 2000) - 2000}`
  const seriesUpdatedColor = series?.map(item => ({
    ...item,
    color: item.name === currentFYName ? colours.blue : item.color
  }))
  const locationId = getLocationId(currentLocation)
  const { currFy, prevFy, domainDeliveries, currFyName, prevFyName } = useCustomerDeliveryData(locationId)
  const deliveriesClimateFootprintGoal =
    goals[dataAvailability?.planetCurrentFY ?? 2024]?.deliveriesClimateFootprintGoal
  const lastDefinedDate = new Date(
    _.findLast(currFy, d => d.footprint !== null)?.readableDate ??
      _.findLast(prevFy, d => d.footprint !== null)?.readableDate ??
      0
  )
  const deliveriesFootprintTonnesYtd = Math.round(
    currFy.map(deliveryToDatapoint).filter(d => isEqual(d.x, lastDefinedDate))[0]?.y ??
      prevFy.map(deliveryToDatapoint).filter(d => isEqual(d.x, lastDefinedDate))[0]?.y
  )
  const totalFootprintSerie: Serie[] = [
    {
      name: prevFyName,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: prevFy.map(deliveryToDatapoint).map(i => ({ x: new Date(i.x.setFullYear(i.x.getFullYear() + 1)), y: i.y }))
    },
    {
      name: currFyName,
      color: colours.darkBlue1,
      data: currFy.map(deliveryToDatapoint).filter(d => isEqual(d.x, lastDefinedDate) || isBefore(d.x, lastDefinedDate))
    },
    deliveriesClimateFootprintGoal
      ? {
          id: 'goal',
          name: `${currFyName} Goal`,
          color: colours.lightBlue2,
          data: currFy.map((d, index) => ({
            x: new Date(d.readableDate),
            y: ((deliveriesClimateFootprintGoal / 12) * (index + 1)) / 1000
          }))
        }
      : false
  ].filter(Boolean) as Serie[]

  const { energyEffRolling, energyEffSeries, energyEffUnit, energyEffDates, energyEffValue } =
    useEnergyEfficiencyRolling(siteOrCountry, func)

  const energyEfficiencyGoal = goals[dataAvailability?.planetCurrentFY ?? 2024]?.energyEfficiencyGoal
  const energyEffOnTrack = !energyEfficiencyGoal || (energyEfficiencyGoal ?? 0) >= (energyEffValue ?? 0)
  const energyEffSeriesWithGoal = energyEffSeries
    ? ([
        ...energyEffSeries,
        energyEfficiencyGoal
          ? {
              name: `FY${(dataAvailability?.energyCurrentFY ?? 2024) - 2000} Goal`,
              color: colours.lightBlue2,
              data: energyEffRolling.map(d => ({ x: new Date(d.readableDate), y: energyEfficiencyGoal }))
            }
          : false
      ].filter(Boolean) as Serie[])
    : []

  const { waterSeries, waterFootprintUnit, waterDates, waterFootprintYtd, waterTrack } = useWaterFootprint(
    siteOrCountry,
    func
  )

  const CustomerDeliveriesGraphData: GraphProps[] = func.some(item => item === 'Common Areas' || item === 'Tenants')
    ? []
    : [
        {
          id: TableRowKey.PlanetFootprint,
          title: 'Customer Deliveries',
          page: Route.CustomerDeliveriesKPIPage,
          value: deliveriesFootprintTonnesYtd,
          unit: footprintTonnesYtdUnit,
          series: totalFootprintSerie,
          domain: domainDeliveries,
          dateFormat: 'monthWithYear',
          tooltipUnit: 'tonnes CO2e',
          footer: footer
        }
      ]

  const FoodIngredientsGraphData: GraphProps[] = func.some(item => item === 'Common Areas' || item === 'Tenants')
    ? []
    : [
        {
          id: TableRowKey.PlanetFootprint,
          title: 'Food Ingredients Sold',
          page: Route.FoodIngredientsKPIPage,
          value: foodFootprintTonnesYtd,
          unit: footprintTonnesYtdUnit,
          series: foodFootprintSeries,
          domain: foodDates,
          dateFormat: 'monthWithYear',
          tooltipUnit: 'tonnes CO2e',
          onTrack: onTrackFood,
          footer: footer
        }
      ]

  return (
    <SnapshotTopCard
      graphs={[
        {
          id: TableRowKey.PlanetFootprint,
          title: 'Operational Climate Footprint',
          page: Route.ClimateKPIPage,
          value: footprintTonnesYtd,
          unit: footprintTonnesYtdUnit,
          series: seriesUpdatedColor,
          domain: dates,
          dateFormat: 'monthWithYear',
          tooltipUnit: 'tonnes CO2e',
          onTrack: onTrack,
          footer: footer
        },
        ...FoodIngredientsGraphData,
        {
          id: TableRowKey.PlanetFootprint,
          title: 'Energy Efficiency',
          page: Route.EnergyEfficiencyKPIPage,
          value: energyEffValue,
          unit: energyEffUnit,
          series: energyEffSeriesWithGoal,
          domain: energyEffDates,
          dateFormat: 'monthWithYear',
          tooltipUnit: energyEffUnit,
          onTrack: energyEffOnTrack,
          footer: footer
        },
        ...CustomerDeliveriesGraphData,
        {
          id: TableRowKey.PlanetFootprint,
          title: 'Water Efficiency',
          page: Route.WaterEfficiencyKPIPage,
          value: waterFootprintYtd,
          unit: waterFootprintUnit,
          series: waterSeries,
          domain: waterDates,
          dateFormat: 'monthWithYear',
          tooltipUnit: '%',
          onTrack: waterTrack,
          footer: footer,
          showDecimals: true
        }
      ]}
      label="Climate Positive"
      headerRoute={Route.ClimateKPIPage}
    />
  )
}

const formatChangeMakers = (data: ChangeMakers[], goal: number | undefined): [number, Serie[], Date[]] => {
  const splitByFy = data.reduce<Record<string, ChangeMakers[]>>((acc, val) => {
    const year = getYear(getIngkaFinancialYear(new Date(val.readableDate)))
    if (acc[year]) acc[year].push(val)
    else acc[year] = [val]
    return acc
  }, {})

  const [fiscalYearA, fiscalYearB] = Object.keys(splitByFy)

  const isASmaller = parseInt(fiscalYearA) < parseInt(fiscalYearB)

  const getTotal = (value: ChangeMakers) => (value.coworkers ?? 0) + (value.customers ?? 0)

  const total = splitByFy[isASmaller ? fiscalYearB : fiscalYearB].reduce((acc, val) => (acc += getTotal(val)), 0)

  const calcYtd = (values: ChangeMakers[], stopIndex: number) => {
    let total = 0
    for (let i = 0; i <= stopIndex; i++) {
      total += getTotal(values[i])
    }
    return total
  }

  const date = endOfMonth(new Date())

  const commonYear = parseInt(fiscalYearA)

  const domain = splitByFy[fiscalYearA].map(value => setYear(new Date(value.readableDate), commonYear))
  const findNeededDate = (readableDate: string) => {
    return domain.find(item => item.getMonth() === new Date(readableDate).getMonth()) || new Date()
  }

  const series: Serie[] = [
    {
      color: isASmaller ? colours.offWhite1 : colours.blue,
      data: splitByFy[fiscalYearA].reduce<DataPoint[]>((acc, value, i) => {
        if (date.getTime() - new Date(value.readableDate).getTime() > 0) {
          acc.push({
            x: findNeededDate(value.readableDate),
            y: calcYtd(splitByFy[fiscalYearA], i)
          })
        }
        return acc
      }, []),
      name: `FY${fiscalYearA.slice(-2)}`,
      ...(isASmaller && { fill: colours.grey1 })
    },
    {
      color: isASmaller ? colours.blue : colours.offWhite1,
      data: splitByFy[fiscalYearB].reduce<DataPoint[]>((acc, value, i) => {
        if (date.getTime() - new Date(value.readableDate).getTime() > 0) {
          acc.push({
            x: findNeededDate(value.readableDate),
            y: calcYtd(splitByFy[fiscalYearB], i)
          })
        }
        return acc
      }, []),
      name: `FY${fiscalYearB.slice(-2)}`,
      ...(!isASmaller && { fill: colours.grey1 })
    },
    ...(goal
      ? [
          {
            color: colours.lightBlue2,
            data: splitByFy[fiscalYearB].map((value, index) => ({
              x: findNeededDate(value.readableDate),
              y: (goal / 12) * (index + 1)
            })),
            name: 'Goal'
          }
        ]
      : [])
  ]

  return [total, series, domain]
}

const useChangeMakers = (
  siteOrCountry: string,
  func: SiteFunction[],
  changeMakersGoal?: number
): [number, Serie[], Date[]] => {
  const { clusters } = useLocations()
  const locationId = getLocationOrDefault()
  const [series, setSeries] = React.useState<Serie[]>([])
  const [dates, setDates] = React.useState<Date[]>([])
  const [total, setTotal] = React.useState<number>(0)

  const locationSelector = getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes)

  React.useEffect(() => {
    setTotal(0)
    setSeries([])
    setDates([])

    async function getChangeMakersData() {
      const { data } = await getChangeMakers({
        ...locationSelector,
        func,
        isOld: true,
        prevFy: true
      })

      const [_total, formattedSeries, _dates] = formatChangeMakers(data, changeMakersGoal)

      setTotal(_total)
      setSeries(formattedSeries)
      setDates(_dates)
    }

    getChangeMakersData()
  }, [siteOrCountry, JSON.stringify(func), changeMakersGoal])

  return [total, series, dates]
}
export const SocialImpactTopCard: React.FC<TopCardProps> = ({ footer, siteOrCountry, goals }) => {
  const [{ func }] = useSharedSelections()
  const { clusters } = useLocations()
  const [totalImpacted, setTotalImpacted] = React.useState(-1)
  const [peopleImpactedCurrentFy, setPeopleImpactedCurrentFy] = React.useState<PeopleImpacted[]>([])
  const [peopleImpactedPreviousFy, setPeopleImpactedPreviousFy] = React.useState<PeopleImpacted[]>([])
  const [actualPeriodDate, setActualPeriodDate] = React.useState<string>()
  const { dataAvailability } = useDataAvailabilityContext()
  const goalsForLocation = goals[dataAvailability?.socialImpactCurrentFY ?? 0]
  const socialImpactGoal = goalsForLocation?.socialImpactGoal
  const changeMakersGoal = goalsForLocation?.changeMakersGoal

  const [changeMakersTotal, changeMakersSeries, changeMakersDomain] = useChangeMakers(
    siteOrCountry,
    func,
    changeMakersGoal
  )

  const selectorCurrentFy = {
    ...getLocationSelector(siteOrCountry, getCluster(clusters, siteOrCountry)?.countryCodes),
    start_fy: dataAvailability?.socialImpactCurrentFY,
    end_fy: dataAvailability?.socialImpactCurrentFY,
    func,
    isOld: true
  }
  const selectorPreviousFy = {
    ...getLocationSelector(siteOrCountry, getCluster(clusters, siteOrCountry)?.countryCodes),
    start_fy: dataAvailability?.socialImpactPreviousFY,
    end_fy: dataAvailability?.socialImpactPreviousFY,
    func,
    isOld: true
  }

  React.useEffect(() => {
    setTotalImpacted(0)
    setPeopleImpactedCurrentFy([])
    setPeopleImpactedPreviousFy([])

    const getSocialImpactData = async () => {
      const [currentyFy, previousFy] = await Promise.all([
        getPeopleImpacted(selectorCurrentFy),
        getPeopleImpacted(selectorPreviousFy)
      ]).then(([curr, prev]) => [curr, prev.data] as const)

      setActualPeriodDate(currentyFy.actualPeriod)
      setTotalImpacted(sumBy(currentyFy.data, getPositivelyImpacted))
      setPeopleImpactedCurrentFy(currentyFy.data)
      setPeopleImpactedPreviousFy(previousFy)
    }

    getSocialImpactData()
  }, [JSON.stringify(func), siteOrCountry])

  const series = compact([
    socialImpactGoal && {
      id: 'goal',
      name: 'goal',
      data: peopleImpactedPreviousFy.map((d, index) => ({
        x: new Date(d.readableDate),
        y: (socialImpactGoal / 12) * (index + 1),
        label: `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} Goal`
      })),
      color: colours.lightBlue2,
      zIndex: 3
    },
    {
      id: 'ongoing',
      name: `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000}`,
      data: peopleImpactedCurrentFy
        .filter(item => new Date(item.readableDate) <= new Date(actualPeriodDate || ''))
        .map((p, index) => {
          const itemPrevFY = peopleImpactedPreviousFy.find(
            item => new Date(item.readableDate).getMonth() === new Date(p.readableDate).getMonth()
          )
          return {
            x: new Date(itemPrevFY?.readableDate || ''),
            y: sumBy(peopleImpactedCurrentFy.slice(0, index + 1), getPositivelyImpacted),
            label: `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000}`
          }
        }),
      color: colours.blue,
      zIndex: 4
    },
    {
      id: 'previous',
      name: `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000}`,
      data: peopleImpactedPreviousFy.map((p, index) => ({
        x: new Date(p.readableDate),
        y: sumBy(peopleImpactedPreviousFy.slice(0, index + 1), getPositivelyImpacted),
        label: `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000}`
      })),
      color: colours.offWhite1,
      fill: colours.grey1,
      zIndex: 2
    }
  ])

  const domain = peopleImpactedPreviousFy.map(({ readableDate }) => new Date(readableDate))

  return (
    <SnapshotTopCard
      graphs={[
        {
          id: 'social-impact-kpi',
          title: 'Social Impact',
          page: Route.SocialImpactKPIPage,
          value: totalImpacted === -1 ? NaN : totalImpacted,
          unit: `people impacted`,
          series: series,
          domain: domain,
          dateFormat: 'monthWithYearFy',
          tooltipUnit: '',
          valueFormatter: formatAbsoluteNumber,
          footer: footer
        },
        {
          id: 'social-impact-kpi',
          title: 'Change-makers',
          page: Route.ChangeMakersKPIPage,
          value: changeMakersTotal,
          unit: `Change-makers engaged`,
          series: changeMakersSeries,
          domain: changeMakersDomain,
          dateFormat: 'month',
          tooltipUnit: '',
          valueFormatter: formatAbsoluteNumber,
          footer: footer
        }
      ]}
      label="Fair & Equal"
      headerRoute={Route.SocialImpactKPIPage}
    />
  )
}

export const ZeroWasteTopCard: React.FC<TopCardProps> = ({ footer, siteOrCountry, goals }) => {
  const [{ func }] = useSharedSelections()
  const [waste, setWaste] = React.useState<ApiTotalFootprint[]>([])
  const [domain, setDomain] = React.useState<Date[]>([])
  const { dataAvailability } = useDataAvailabilityContext()
  const { clusters, currentLocation } = useLocations()
  const locationId = getLocationId(currentLocation)

  const baseSelector = {
    start_fy: dataAvailability?.planetPreviousFY,
    end_fy: dataAvailability?.planetCurrentFY,
    func,
    isOld: true
  }
  const countryOrLocationId = currentLocation.isCluster
    ? currentLocation.cluster.clusterId
    : currentLocation.location.countryCode

  const currFy = (dataAvailability?.planetCurrentFY ?? 2000) - 2000
  const prevFy = (dataAvailability?.planetPreviousFY ?? 2000) - 2000

  React.useEffect(() => {
    setWaste([])
    setDomain([])
    getTotalFootprint(
      {
        ...getLocationSelector(countryOrLocationId, getCluster(clusters, countryOrLocationId)?.countryCodes),
        ...baseSelector
      },
      ['waste']
    ).then(response => {
      setDomain(response.dates.map(d => new Date(d)))
      setWaste(response.data)
    })
  }, [JSON.stringify(func), siteOrCountry])

  const getLocationFilters = (siteId: string) =>
    currentLocation.isCluster || isCountryOrGlobal(currentLocation.location) || siteId === locationId

  const wasteDataForLocation = React.useMemo(() => {
    return waste
      ? waste.filter(w => getLocationFilters(w.siteId)).map(w => ({ ...w, quantity: w.quantity / 1000 }))
      : undefined
  }, [JSON.stringify(waste)])

  const recycling = flatMap(domain, d => {
    const dataForDate = wasteDataForLocation?.filter(w => isSameMonth(new Date(w.readableDate), d))
    if (!dataForDate || dataForDate.length === 0) {
      return []
    }
    const fiscalYear = dataForDate[0].fiscalYear
    const nonZero = calculateTotalNonZeroWaste(dataForDate)
    const total = dataForDate.reduce((acc, w) => acc + w.quantity, 0) / 1000
    return { date: d, fiscalYear, recyclingShare: ((total - nonZero) / total) * 100 }
  })
  const recyclingSeries =
    recycling.length === 0
      ? []
      : [prevFy, currFy].map((fy, i) => ({
          id: `FY${fy}`,
          name: `FY${fy}`,
          data: recycling
            .filter(r => r.fiscalYear === fy)
            .map(r => {
              const date = domain.find(item => item.getMonth() === r.date.getMonth())
              return { label: `FY${fy}`, x: date || new Date(), y: r.recyclingShare }
            }),
          color: i === 0 ? colours.offWhite1 : colours.purple1,
          fill: i === 0 ? colours.grey1 : undefined,
          zIndex: i,
          unit: '%'
        }))

  const chartDomain = recyclingSeries.length > 0 ? recyclingSeries[0].data.map(x => x.x) : []

  const currLocationRecyclingRateGoal = goals[dataAvailability?.planetCurrentFY ?? 2024]?.recyclingRateGoal ?? 0
  const recyclingSeriesWithGoals = recyclingSeries
    ? [
        ...recyclingSeries,
        {
          color: '#7d8fad',
          data: chartDomain.map(date => ({
            x: date,
            y: currLocationRecyclingRateGoal
          })),
          fill: undefined,
          name: 'Goal',
          zIndex: 0,
          unit: '%'
        }
      ]
    : []

  const ytdValue = calculateBenchmarkingRecyclingRate(
    waste.filter(w => w.fiscalYear === currFy && getLocationFilters(w.siteId))
  )

  return (
    <SnapshotTopCard
      graphs={[
        {
          id: 'zerowaste',
          title: 'Zero Waste',
          page: Route.ZeroWasteKPIPage,
          value: ytdValue,
          unit: '% recycling rate ytd',
          series: recyclingSeriesWithGoals,
          domain: chartDomain,
          startFromZero: false,
          dateFormat: 'monthWithYearFy',
          tooltipUnit: 'waste tonnes',
          onTrack: ytdValue >= currLocationRecyclingRateGoal,
          footer: footer
        }
      ]}
      label="Circular"
      headerRoute={Route.ZeroWasteKPIPage}
    />
  )
}

export const SnapshotTopCards: React.FC<SnapshotTopCardsProps> = ({
  siteOrCountry,
  currentLocation
}: SnapshotTopCardsProps) => {
  const [prevFYGoals, setPrevFyGoals] = React.useState<SiteGoals | CountryGoals>()
  const [currFYGoals, setCurrFyGoals] = React.useState<SiteGoals | CountryGoals>()
  const [{ functionArea, func }] = useSharedSelections()
  const { dataAvailability } = useDataAvailabilityContext()
  const { locations } = useLocations()
  const locationId = getLocationOrDefault()

  const fyLastTwoDigits = (dataAvailability?.profitCurrentFY ?? 2000) - 2000
  const header = `KPI Performance FY${fyLastTwoDigits} YTD`

  const showCentres = functionArea === 'centres' || difference(func, ['Common Areas', 'Tenants']).length === 0

  const centreCards: CardType[] = ['zero-waste', 'planet', 'social-impact']
  const nonCentreCards: CardType[] = ['healthy-living', 'zero-waste', 'social-impact', 'planet']
  const nonClusterCards: CardType[] = []

  const selectedCards: CardType[] = [
    ...(showCentres ? centreCards : nonCentreCards),
    ...(!showCentres && !currentLocation.isCluster ? nonClusterCards : [])
  ]

  const countryCode = getCountry(locationId, locations).countryCode
  const currentFY = dataAvailability?.foodCurrentFY ?? 0

  React.useEffect(() => {
    if (!isCluster(locationId)) {
      setPrevFyGoals(undefined)
      setCurrFyGoals(undefined)

      getGoals(countryCode, func, currentFY).then(goals => {
        const goalsForLocation = getGoalsForLocation(locationId, goals)
        setCurrFyGoals(goalsForLocation)
      })
      getGoals(countryCode, func, currentFY - 1).then(goals => {
        const goalsForLocation = getGoalsForLocation(locationId, goals)
        setPrevFyGoals(goalsForLocation)
      })
    }
  }, [JSON.stringify(currentLocation), JSON.stringify(func)])

  return (
    <>
      <div className="SnapshotTopCardsHeader">{header}</div>
      <div className="SnapshotTopCards">
        <SnapshotCardGenerator
          selectedCards={selectedCards}
          currentLocation={currentLocation}
          siteOrCountry={siteOrCountry}
          goals={{ [currentFY]: currFYGoals, [currentFY - 1]: prevFYGoals }}
        />
      </div>
    </>
  )
}

const SnapshotCardGenerator = ({ selectedCards, ...props }: SnapshotCardGeneratorProps) => {
  const cardMapping = {
    'zero-waste': <ZeroWasteTopCard {...props} />,
    planet: <PlanetTopCard {...props} />,
    'social-impact': <SocialImpactTopCard {...props} />,
    'healthy-living': <HealthyLivingTopCard {...props} />
  }

  return (
    <>
      {selectedCards.map(cardType => (
        <>{cardMapping[cardType]}</>
      ))}
    </>
  )
}

interface SnapshotTopCardProps {
  label: string
  headerRoute: string
  graphs: GraphProps[]
}

interface GraphProps {
  id: string
  title: string
  page: Route
  value: number
  unit: string
  series: Serie[] | undefined
  domain: Date[] | undefined
  dateFormat: DateFormat
  tooltipUnit: string
  maxValue?: number
  onTrack?: boolean
  footer?: React.ReactNode
  informationText?: string
  valueFormatter?: (x: number) => string
  startFromZero?: boolean
  tooltipItemsFn?: (date: Date, data: TooltipItem[]) => TooltipItem[]
  showDecimals?: boolean
}

const SnapshotGraph = ({
  id,
  title,
  page,
  value,
  unit,
  series,
  domain,
  dateFormat,
  // tooltipUnit,
  maxValue,
  onTrack,
  footer,
  informationText,
  valueFormatter = formatAbsoluteNumber,
  startFromZero,
  tooltipItemsFn,
  showDecimals
}: GraphProps) => {
  const isLoading = series?.map(({ data }) => data.length).filter(x => x > 0).length === 0
  const isEmpty = every(
    flatMap(series, serie => every(serie.data, d => d.y == null)),
    hasContent => hasContent
  )
  return (
    <div className={`SnapshotCard ${id}`}>
      <Link page={page} className="SnapshotLink">
        <div className="SnapshotCardHeader">
          <h3>{title}</h3>
          <ArrowIcon angle={90} width={16} height={16} color={colours.offWhite3} />
        </div>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <div className={classNames('Description', { OnTrack: onTrack, NotOnTrack: onTrack === false })}>
              {Number.isFinite(value) && (
                <div className="Value" data-testid="snapshot-topcard-value">
                  {unit.includes('%') || showDecimals ? formatRelativeNumber(value) : valueFormatter(value)}
                </div>
              )}
              <div className="Unit">{Number.isNaN(value) ? '' : unit}</div>
              {informationText && (
                <Tooltip tooltipText={informationText} className={classNames('SnapshotCardInformation', 'Icon')}>
                  <InformationIndicator fill={colours.grey4} />
                </Tooltip>
              )}
            </div>
            <div className="Graph">
              {isEmpty ? (
                <NoDataViewSmall />
              ) : (
                <ChartContainer
                  generator={lineChart}
                  series={series}
                  domain={domain}
                  dateFormat={dateFormat}
                  lineChartConfiguration={{ focusStyle: 'none', startFromZero: startFromZero || false }}
                  maxValue={maxValue}
                  hideLegend
                  hideGuides
                  hideXAxis
                  hideYAxis
                  tooltipItemsFn={tooltipItemsFn ? tooltipItemsFn : undefined}
                  showDecimals={showDecimals}
                  isSmallGraph
                />
              )}
            </div>
            {footer ? <div className="SnapshotCardFooter">{footer}</div> : null}
          </>
        )}
      </Link>
    </div>
  )
}
const SnapshotTopCard = ({ graphs, headerRoute, label }: SnapshotTopCardProps) => {
  const filterGraph: GraphProps[] = graphs.filter(g => g.title !== 'Customer Deliveries' || !Number.isNaN(g.value))
  const graphElements = graphs.map(() => useRef<HTMLDivElement>(null))
  const [headers, setHeaders] = React.useState<
    Record<number, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
  >({})

  function drawHeaders() {
    const yPosOfGraphs = graphElements.map(element => element.current?.offsetTop)
    const splitYPos = yPosOfGraphs.reduce<Record<string, number>>((acc, val) => {
      if (val === undefined) return acc
      if (!acc[`${val}`]) acc[`${val}`] = 1
      else acc[`${val}`]++
      return acc
    }, {})
    const headers: Record<number, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = {}
    for (const [yPos, amount] of Object.entries(splitYPos)) {
      const index = graphElements.findIndex(element => element.current?.offsetTop === parseInt(yPos))
      const width = graphElements[index]?.current?.offsetWidth
      headers[index] = (
        <div
          className={classNames('SnapshotCardLabel', `${headerRoute}`)}
          style={{
            position: 'absolute',
            top: '-50px',
            left: 0,
            width: `${(width || 0) * amount - 21}px`
          }}
        >
          <div className="LabelText">{label}</div>
        </div>
      )
    }
    setHeaders(headers)
  }

  React.useEffect(() => {
    drawHeaders()
    window.addEventListener('resize', drawHeaders)

    return () => {
      window.removeEventListener('resize', drawHeaders)
    }
  }, [graphs])

  return (
    <>
      {filterGraph.map((graph, i) => (
        <div key={i} ref={graphElements[i]} data-type={graph.id} className="SnapshotLabelAndCard">
          {i in headers ? headers[i] : null}
          <div key={i} className="SnapshotGraphContainer">
            <SnapshotGraph key={i} {...graph} />
          </div>
        </div>
      ))}
    </>
  )
}
