import React from 'react'
import { format, parse, subWeeks } from 'date-fns'
import SkapaModal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import { WeeklyHfbArticleSalesData, WeeklySalesData } from '../../api/src/common-types'
import { lineChart } from './BaseGraphs/GraphUtil'
import { NoDataViewSmall } from './BaseGraphs/NoDataView'
import './GraphSideBarCards.scss'
import colours from '../Colours.module.scss'
import { ArticlesGraph } from './TopSellingArticlesGraph'
import { ArrowIcon } from './ArrowIcon'
import { formatWeekRangeTexts } from './HFBSharesGraph'
import { formatAbsoluteNumber } from './Utils/format'
import { PortalWrapper } from './Utils/PortalWrapper'
import { LoadingSkeleton } from './LoadingSkeleton'
import { useLocations, LanguageContext } from '../context'
import { getCurrencyCode } from './Utils/utils'
import { ChartContainer, DataPoint, Serie, TooltipItem } from './BaseGraphs/ChartContainer'

const calculateAsIsSalesPercentage = (x: WeeklySalesData, isSales: boolean): [number, number] => {
  if (x) {
    if (isSales) {
      return [(x.asisSales / x.totalSales) * 100, (x.asisSalesFY / x.totalSalesFY) * 100]
    } else {
      return [(x.asisQty / x.totalQty) * 100, (x.asisQtyFY / x.totalQtyFY) * 100]
    }
  }
  return [0, 0]
}

export const calculatePppSalesPercentage = (x: WeeklySalesData, isSales: boolean): [number, number] => {
  if (x)
    if (isSales) {
      return [
        ((x.asisSales + x.pppSales) / x.totalSales) * 100,
        ((x.asisSalesFY + x.pppSalesFY) / x.totalSalesFY) * 100
      ]
    } else {
      return [((x.asisQty + x.pppQty) / x.totalQty) * 100, ((x.asisQtyFY + x.pppQtyFY) / x.totalQtyFY) * 100]
    }
  return [0, 0]
}

interface SalesChartProps {
  sales: WeeklySalesData[]
  profitShareGoal?: number
  isSales: boolean
}

export const AsIsSalesChart: React.FC<SalesChartProps> = ({ sales, isSales }) =>
  sales.length > 0 ? (
    createSalesChart(sales, 'asis', calculateAsIsSalesPercentage, isSales, undefined)
  ) : (
    <LoadingSkeleton />
  )
export const PppSalesChart: React.FC<SalesChartProps> = ({ sales, profitShareGoal, isSales }) =>
  sales.length > 0 ? (
    createSalesChart(sales, 'ppp', calculatePppSalesPercentage, isSales, profitShareGoal)
  ) : (
    <LoadingSkeleton />
  )

export const calculatePppSeries = (
  sales: WeeklySalesData[],
  calculatePercentage: (data: WeeklySalesData, isSales: boolean) => [number, number],
  currFyColour: string,
  goalColour: string,
  isSales: boolean,
  profitShareGoal?: number
) => {
  const currentFy = Math.max(...sales.map(t => t.fiscalYear))
  const data = sales
    .filter(({ fiscalYear }) => fiscalYear === currentFy)
    .map(currentFySales => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const prevFySales = sales.find(({ readableDate }) => currentFySales.compDate === readableDate)!

      return [
        {
          x: new Date(currentFySales.readableDate),
          y: calculatePercentage(currentFySales, isSales)[0] || undefined
        },
        {
          x: new Date(currentFySales.readableDate),
          y: calculatePercentage(prevFySales, isSales)[0] || undefined
        },
        profitShareGoal ? { x: new Date(currentFySales.readableDate), y: profitShareGoal * 100 } : false
      ].filter(Boolean) as DataPoint[]
    })

  return [
    {
      id: 'ongoing',
      name: `FY${currentFy}`,
      data: data.map(d => d[0]),
      color: currFyColour,
      zIndex: 3
    },
    {
      id: 'prev',
      name: `FY${currentFy - 1}`,
      data: data.map(d => d[1]),
      color: colours.offWhite1,
      fill: colours.grey1,
      zIndex: 1
    },
    profitShareGoal
      ? {
          id: 'goal',
          name: `FY${currentFy} goal`,
          data: data.map(d => d[2]),
          color: goalColour,
          zIndex: 2
        }
      : false
  ].filter(Boolean) as Serie[]
}

export const getPppTooltipItemsFn =
  (
    sales: WeeklySalesData[],
    calculatePercentage: (data: WeeklySalesData, isSales: boolean) => [number, number],
    currFyColour: string,
    goalColour: string,
    serie: 'ppp' | 'asis',
    isSales: boolean,
    profitShareGoal?: number
  ) =>
  (date: Date) => {
    const readableDate = format(date, 'yyyy-MM-dd')
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentFySales = sales.find(t => t.readableDate === readableDate)!
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const prevFySales = sales.find(t => t.readableDate === currentFySales.compDate)!

    return [
      {
        name: `FY${currentFySales.fiscalYear}`,
        color: currFyColour,
        value: calculatePercentage(currentFySales, isSales)[0],
        unit: '%'
      },
      {
        name: `FY${prevFySales.fiscalYear}`,
        color: colours.offWhite1,
        value: calculatePercentage(prevFySales, isSales)[0],
        unit: '%'
      },
      {
        name: `YTD FY${currentFySales.fiscalYear}`,
        color: 'transparent',
        value: calculatePercentage(currentFySales, isSales)[1],
        unit: '%'
      },
      currentFySales.fiscalYear !== prevFySales.fiscalYear
        ? {
            name: `YTD FY${prevFySales.fiscalYear}`,
            color: 'transparent',
            value: calculatePercentage(prevFySales, isSales)[1],
            unit: '%'
          }
        : { name: 'Invalid', color: 'transparent', value: NaN },
      serie === 'ppp' && profitShareGoal
        ? {
            name: `Goal FY${currentFySales.fiscalYear}`,
            value: (profitShareGoal ?? 0) * 100,
            color: goalColour,
            unit: '%'
          }
        : { name: 'Invalid', color: 'transparent', value: NaN }
    ].filter(v => Number.isFinite(v.value)) as TooltipItem[]
  }

const createSalesChart = (
  sales: WeeklySalesData[],
  serie: 'ppp' | 'asis',
  calculatePercentage: (data: WeeklySalesData, isSales: boolean) => [number, number],
  isSales: boolean,
  profitShareGoal?: number
) => {
  const salesSeries = calculatePppSeries(
    sales,
    calculatePercentage,
    colours.salmon1,
    colours.offWhite5,
    isSales,
    profitShareGoal
  ).slice(0, serie === 'ppp' ? 3 : 2)
  const domain = salesSeries[0].data.map(x => x.x)
  return (
    <div className="GraphSideBarCard">
      <div className="CardHeading">
        <h3>
          {serie === 'ppp' ? 'P+PP' : 'As-Is'} {isSales ? 'Sales' : 'Quantity'} share %
        </h3>
      </div>
      <ChartContainer
        generator={lineChart}
        series={salesSeries}
        domain={domain}
        dateFormat="week"
        hideGuides
        hideXAxis
        lineChartConfiguration={{ startFromZero: false, focusStyle: 'none' }}
        tooltipItemsFn={getPppTooltipItemsFn(
          sales,
          calculatePercentage,
          colours.salmon1,
          colours.offWhite5,
          serie,
          isSales,
          profitShareGoal
        )}
        withDynamicFormatting
        showDecimals
      />
    </div>
  )
}

interface TopArticlesListProps {
  topArticles: WeeklyHfbArticleSalesData[]
  siteOrCountry: string
  isSales: boolean
}

export const TopArticlesList = ({ topArticles, siteOrCountry, isSales }: TopArticlesListProps) => {
  const [isListOpen, setListOpen] = React.useState(false)
  const lang = React.useContext(LanguageContext)
  const { currentLocation } = useLocations()
  const currencyCode = isSales ? getCurrencyCode(currentLocation) : 'QTY'

  const week = topArticles.length > 0 ? topArticles[0].weekNumber : undefined
  const weekDate = week ? parse(week.toString(), 'RRRRII', new Date()) : new Date()
  const { currentPeriod } = formatWeekRangeTexts(subWeeks(weekDate, 4), weekDate, lang)

  const isGlobal = siteOrCountry === 'ALL'
  const sortedTopArticles = topArticles.sort(
    (a, b) =>
      (isSales ? (isGlobal ? b.pppAsisSalesEur : b.pppAsisSalesLocal) : b.pppAsisQty) -
      (isSales ? (isGlobal ? a.pppAsisSalesEur : a.pppAsisSalesLocal) : a.pppAsisQty)
  )

  return (
    <>
      <div className="CardHeading FirstItem">
        Top selling P+PP Products from all HFB categories
        <div className="Right">
          <span className="Label">{currentPeriod}</span>
        </div>
      </div>
      {sortedTopArticles?.length ? (
        <>
          <div className="TopArticlesTable">
            {sortedTopArticles.slice(0, 5).map((article, index) => (
              <React.Fragment key={article.itemNo}>
                <div>{index + 1}.</div>
                <div>{article.itemName}</div>
                <div className="Sales">
                  {formatAbsoluteNumber(
                    Math.round(
                      isSales ? (isGlobal ? article.pppAsisSalesEur : article.pppAsisSalesLocal) : article.pppAsisQty
                    )
                  )}{' '}
                  {currencyCode}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="OpenModalLink" onClick={_ => setListOpen(true)}>
            <ArrowIcon angle={90} width={20} height={20} color={colours.offWhite3} />
            <div>View details</div>
          </div>
        </>
      ) : (
        <div className="NoDataWrapper">
          <NoDataViewSmall />
        </div>
      )}
      <PortalWrapper>
        <SkapaModal visible={isListOpen} handleCloseBtn={_ => setListOpen(false)}>
          <Sheets
            header={<ModalHeader title="Top selling P+PP Products from all HFB categories" />}
            size="large"
            footer={null}
          >
            <ModalBody>
              <ArticlesGraph
                topSellingItems={sortedTopArticles}
                hfbName="Top 10 Selling P+PP products"
                siteOrCountry={siteOrCountry}
                isSales={isSales}
              />
            </ModalBody>
          </Sheets>
        </SkapaModal>
      </PortalWrapper>
    </>
  )
}
