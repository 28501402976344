import { format, isBefore, isEqual } from 'date-fns'
import { chain, extend, sumBy, toPairs, uniq } from 'lodash'
import React, { Fragment } from 'react'
import { ChangeMakers, FairAndEqualBenchmark, GoalsResponse, SiteFunction } from '../../../../api/src/common-types'
import { Serie } from '../../../components/BaseGraphs/ChartContainer'
import { FinancialYearChartContainer } from '../../../components/BaseGraphs/FinancialYearChartContainer'
import { lineChart } from '../../../components/BaseGraphs/GraphUtil'
import { ChangeMakersGraphSideBarCards } from '../../../components/GraphSideBarCards'

import colours from '../../../Colours.module.scss'
import {
  Benchmarking,
  BenchmarkingModal,
  CardRow,
  DataSourceAndModalButton,
  KPI,
  KPIPerformance,
  HeadingItem,
  MainCard,
  RenderBenchmarkType,
  sortBenchmarks,
  Stripe
} from '../../../components/KPIPage'
import { retailSiteFunctions, centreSiteFunctions } from '../../../components/LocationSearch'
import { KpiModalState, KpiPageLearnMoreModal } from '../../../components/Modal'
import { PageHeader } from '../../../components/PageHeader'
import { TopBar } from '../../../components/TopBar'
import { formatAbsoluteNumber, formatRelativeNumber } from '../../../components/Utils/format'
import { useDocumentTitle } from '../../../components/Utils/use-document-title'
import {
  getLocationSelector,
  getCluster,
  getLocationOrDefault,
  isCluster,
  getCountry,
  getLocationId,
  getGoalsForLocation,
  isCountryCode
} from '../../../components/Utils/utils'
import { useDataAvailabilityContext, useLocations } from '../../../context'
import { getChangeMakers, getGoals, getChangeMakersBenchmarking } from '../../../lib/APIClient'
import { useSharedSelections } from '../../../SharedSelections'
import { Route } from '../../../routes'
import { isSameFairAndEqualLocation } from '../SocialImpact/SocialImpactKPIPage'
import classNames from 'classnames'
import { getCurrentPeriod, getIngkaFinancialYear } from '../../../components/Utils/dates'

export interface ChangeMakersBenchmark {
  id: string
  label: string
  selectable: boolean
  previousFyResult: number
  previousFyYtd: number
  currentFyYtd: number | null
  goal: number | null
  goalNextFY: number | null
  ratio: number | string
}

function getRenderBenchmark(isCompleteData: boolean, isFunctionAreaCentres: boolean) {
  const renderFairAndEqualBenchmark: RenderBenchmarkType<ChangeMakersBenchmark> = (benchmark, keys, classes) => (
    <React.Fragment key={benchmark.id}>
      <div className={classNames('FirstItem', classes)}>{benchmark.label}</div>
      {isCompleteData && (
        <div className={classNames(classes)}>
          {benchmark[keys[0]] ? formatAbsoluteNumber(Math.round(benchmark[keys[0]] as number)) : 'N/A'}
        </div>
      )}
      <div
        className={classNames(
          'Right',
          classes,
          'YTD',
          benchmark.currentFyYtd && benchmark.goal
            ? benchmark.currentFyYtd > benchmark.goal
              ? 'OnTrack'
              : 'NotOnTrack'
            : 'Black'
        )}
      >
        {benchmark.currentFyYtd ? formatAbsoluteNumber(Math.round(benchmark.currentFyYtd)) : 'N/A'}
      </div>
      {!isFunctionAreaCentres && (
        <div className={classNames('Right', classes, 'YTD', 'Black')}>
          {benchmark.ratio ? formatAbsoluteNumber(Number(benchmark.ratio)) : 'N/A'}
        </div>
      )}
      {isCompleteData && (
        <>
          <div className={classNames(classes)}>{benchmark.goal ? formatAbsoluteNumber(benchmark.goal) : 'N/A'}</div>
          <div className={classNames(classes)}>
            {benchmark.goalNextFY ? formatAbsoluteNumber(benchmark.goalNextFY) : 'N/A'}
          </div>
        </>
      )}
      <div />
    </React.Fragment>
  )
  return renderFairAndEqualBenchmark
}

const totalChangeMakers = (d: ChangeMakers) => (d.coworkers ?? 0) + (d.customers ?? 0)

const getBenchmark = (
  rawBenchmarks: FairAndEqualBenchmark[],
  goals: GoalsResponse | undefined,
  currFy: number,
  locationsRatio: Record<string, number>,
  locationId: string
) => {
  const locationName =
    rawBenchmarks?.find(isSameFairAndEqualLocation(locationId))?.[locationId.length > 3 ? 'siteName' : 'countryName'] ??
    'Global (All Locations)'

  const benchmarksForLocation =
    (locationId === 'ALL' ? rawBenchmarks : rawBenchmarks?.filter(isSameFairAndEqualLocation(locationId))) ?? []

  const prevFy = currFy - 1

  const latestPeriod = getCurrentPeriod(new Date())

  const ratio = locationsRatio[locationId] && formatRelativeNumber(locationsRatio[locationId])

  const prevResult = benchmarksForLocation.filter(x => x.fiscalYear === prevFy - 2000)

  const prevYtd = prevResult.filter(x => x.fiscalPeriod <= latestPeriod && x.siteId !== null)

  const currYtd = benchmarksForLocation.filter(x => x.fiscalYear === currFy - 2000)

  return {
    id: locationId,
    label: locationName,
    selectable: true,
    locationName,
    previousFyResult: sumBy(prevResult, 'total'),
    previousFyYtd: sumBy(prevYtd, 'total'),
    currentFyYtd: sumBy(currYtd, 'total'),
    goal: getGoalsForLocation(locationId, goals)?.changeMakersGoal ?? null,
    goalNextFY: getGoalsForLocation(locationId, goals)?.changeMakersGoalNextFY ?? null,
    ratio
  }
}

const formatKpi = (data: ChangeMakers[]) => {
  const coworkers = sumBy(data, 'coworkers')
  const customers = sumBy(data, 'customers')
  return {
    'Total change-makers engaged': coworkers + customers,
    Coworkers: coworkers,
    Customers: customers
  }
}

export const ChangeMakersKPIPage = () => {
  useDocumentTitle('Change-makers')
  const page = Route.ChangeMakersKPIPage

  const locationId = getLocationOrDefault()
  const [{ functionArea }] = useSharedSelections()
  const { currentLocation, clusters, locations } = useLocations()
  const { dataAvailability } = useDataAvailabilityContext()
  const [modalState, setModalState] = React.useState<KpiModalState>({ isOpen: false })
  const [goal, setGoal] = React.useState<number>()
  const [rawBenchmarks, setRawBenchmarks] = React.useState<FairAndEqualBenchmark[] | null>(null)
  const [locationsRatio, setLocationsRatio] = React.useState({})
  const [benchmarkModalOpen, setBenchmarkModalOpen] = React.useState(false)
  const [goals, setGoals] = React.useState<GoalsResponse>()

  const [lastUpdated, setLastUpdated] = React.useState('')
  const [data, setData] = React.useState<(ChangeMakers & { ytdTotal: number; date: Date })[]>([])
  const [isDataEmpty, setIsDataEmpty] = React.useState(true)

  const currFy = dataAvailability?.socialImpactCurrentFY ?? 2000
  const prevFy = dataAvailability?.socialImpactPreviousFY ?? 2000
  const currFyName = `FY${currFy - 2000}`
  const prevFyName = `FY${currFy - 2001}`

  const func: SiteFunction[] =
    functionArea === 'ingka' ? ['ALL'] : functionArea === 'retail' ? retailSiteFunctions : centreSiteFunctions

  const locationSelector = getLocationSelector(locationId, getCluster(clusters, locationId)?.countryCodes)

  React.useEffect(() => {
    if (!dataAvailability) return
    setIsDataEmpty(false)
    setLastUpdated('')
    setData([])
    setGoal(undefined)
    setRawBenchmarks(null)

    async function fetchChangeMakersData() {
      const [{ lastUpdated, data }, goal] = await Promise.all([
        getChangeMakers({
          ...locationSelector,
          func,
          isOld: true,
          prevFy: true
        }),
        isCluster(locationId)
          ? undefined
          : getGoals(getCountry(locationId, locations).countryCode, func, currFy).then(
              x => getGoalsForLocation(locationId, x)?.changeMakersGoal
            )
      ])

      if (data.every(d => d.coworkers === null && d.customers === null)) {
        setIsDataEmpty(true)
      }

      const dataWithDates = data.map(record => extend(record, { date: new Date(record.readableDate) }))
      const currFyData = dataWithDates.filter(
        item => getIngkaFinancialYear(new Date(item.readableDate)).getFullYear() === currFy
      )
      const prevFyData = dataWithDates.filter(
        item => getIngkaFinancialYear(new Date(item.readableDate)).getFullYear() === prevFy
      )

      const currDataWithYtd = currFyData.map(record => extend(record, { ytdTotal: getYtdSum(record.date, currFyData) }))
      const prevDataWithYtd = prevFyData.map(record => extend(record, { ytdTotal: getYtdSum(record.date, prevFyData) }))

      setLastUpdated(format(new Date(lastUpdated), 'dd/MM/yyyy'))
      setData([...currDataWithYtd, ...prevDataWithYtd])
      setGoal(goal)

      function getYtdSum(date: Date, list: (ChangeMakers & { date: Date })[]) {
        return chain(list)
          .filter(d => isEqual(d.date, date) || isBefore(d.date, date))
          .sumBy(totalChangeMakers)
          .value()
      }
    }

    fetchChangeMakersData()

    getChangeMakersBenchmarking(
      locationSelector.countryCodes.length > 0 || currentLocation.isCluster
        ? locationSelector.countryCodes
        : [currentLocation.location.countryCode],
      func,
      dataAvailability.socialImpactCurrentFY,
      dataAvailability.socialImpactPreviousFY
    ).then(({ data, ratio }) => {
      setRawBenchmarks(data)
      setLocationsRatio(ratio)
    })

    if (!isCluster(locationId)) {
      getGoals(getCountry(locationId, locations).countryCode, func, currFy).then(setGoals)
    }
  }, [locationId, JSON.stringify(dataAvailability), JSON.stringify(locationSelector), JSON.stringify(func)])

  const dataCurrFY = data.filter(item => getIngkaFinancialYear(item.date).getFullYear() === currFy)
  const dataPrevFY = data.filter(item => {
    return getIngkaFinancialYear(item.date).getFullYear() === prevFy
  })

  const formattedKpiPerformance = formatKpi(dataCurrFY)

  const series: Serie[] = [
    goal
      ? {
          id: 'goal',
          name: `${currFyName} Goal`,
          color: colours.lightBlue2,
          data: data.slice(-12).map((d, i) => ({
            x: new Date(d.date),
            y: (Number(goal) / 12) * (i + 1)
          }))
        }
      : false,
    {
      name: currFyName,
      color: colours.darkBlue1,
      data: dataCurrFY.filter(d => d.coworkers || d.customers).map(d => ({ x: d.date, y: d.ytdTotal }))
    },
    {
      name: prevFyName,
      color: colours.offWhite1,
      fill: colours.grey1,
      data: dataPrevFY
        .filter(d => d.coworkers || d.customers)
        .map(d => {
          const currData = dataCurrFY.find(item => item.date.getMonth() === d.date.getMonth())
          return { x: currData?.date, y: d.ytdTotal }
        })
    }
  ].filter(Boolean) as Serie[]

  const benchmarkLocationIds = uniq(rawBenchmarks?.map(x => (locationId === 'ALL' ? x.countryCode : x.siteId)))
  const currentLocationBenchmark = getBenchmark(rawBenchmarks ?? [], goals, currFy, locationsRatio, locationId)
  const countryBenchmark = getBenchmark(
    rawBenchmarks ?? [],
    goals,
    currFy,
    locationsRatio,
    currentLocation.isCluster || locationId.length <= 3 ? locationId : currentLocation.location.countryCode
  )
  const benchmarks = benchmarkLocationIds
    .map(getBenchmark.bind(null, rawBenchmarks ?? [], goals, currFy, locationsRatio))
    .filter(x => x.currentFyYtd || x.previousFyYtd || x.previousFyResult)

  const currFyHeading = `FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000} YTD`
  const prevFyHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} YTD`
  const prevFyResultHeading = `FY${(dataAvailability?.socialImpactPreviousFY ?? 2000) - 2000} Result`
  const goalHeading = `Goal FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) - 2000}`
  const goalHeadingNext = `Goal FY${(dataAvailability?.socialImpactCurrentFY ?? 2000) + 1 - 2000}`
  const goalHeadingYTD = 'YTD Goal'

  const isCompleteData = dataAvailability?.socialImpactCurrentFY !== 2022
  const isFunctionAreaCentres = functionArea === 'centres'
  const benchmarkingHeadersIncompleteData: HeadingItem<ChangeMakersBenchmark>[] = [
    [{ name: currFyHeading, key: 'currentFyYtd' }]
  ]
  const benchmarkingHeadersCompleteData: HeadingItem<ChangeMakersBenchmark>[] = [
    [
      { name: prevFyHeading, key: 'previousFyYtd' },
      { name: prevFyResultHeading, key: 'previousFyResult' }
    ],
    ...benchmarkingHeadersIncompleteData,
    isFunctionAreaCentres
      ? []
      : [
          {
            name: 'Visits Ratio',
            description:
              'Benchmarking ratio = number of change-makers / number of visitors of the country (online & store) * 1.000.000',
            key: 'ratio'
          }
        ],
    [{ name: goalHeading, key: 'goal' }],
    [{ name: goalHeadingNext, key: 'goalNextFY' }]
  ]
  const benchmarkingHeaders = isCompleteData
    ? benchmarkingHeadersCompleteData.filter(h => h.length > 0)
    : benchmarkingHeadersIncompleteData

  const lastDisplayedDate = [...(series?.find(s => s.name === currFyName)?.data || [])].reverse()[0]?.x
  const currentPeriod = getCurrentPeriod(lastDisplayedDate)

  const ytdGoal = (Number(goal) / 12) * currentPeriod

  return (
    <div className="KPIPage">
      <TopBar currentPage={page} useInFlexLayout />
      <PageHeader className="SocialImpactHeader" route={Route.ChangeMakersKPIPage}></PageHeader>
      <div className="PageContent">
        <Stripe title="Change-makers">
          <DataSourceAndModalButton lastUpdated={lastUpdated} onClick={() => setModalState({ isOpen: true, page })} />
        </Stripe>
        <CardRow className="BenchmarkingAndGoals">
          <Benchmarking
            key="benchmarking"
            benchmarks={
              !rawBenchmarks
                ? []
                : sortBenchmarks(
                    [...(isCountryCode(locationId) ? [currentLocationBenchmark] : []), ...benchmarks],
                    'currentFyYtd',
                    locationId
                  ).slice(0, 4)
            }
            label="people engaged"
            headers={benchmarkingHeaders}
            locationId={getLocationId(currentLocation)}
            openModal={() => setBenchmarkModalOpen(true)}
            renderBenchmark={getRenderBenchmark(isCompleteData, isFunctionAreaCentres)}
            totalLocations={benchmarks?.length || 0}
          />
          <KPIPerformance
            key="goals"
            heading="KPI Performance"
            units={['people YTD']}
            kpis={
              data.length > 0
                ? [
                    ...toPairs(formattedKpiPerformance).map(([key, value]): KPI => {
                      const isBold = key === 'Total change-makers engaged'
                      const boldClassName = ['Bold']
                      return {
                        key,
                        value: formatAbsoluteNumber(value),
                        keyClassNames: isBold ? boldClassName : [],
                        valueClassNames: isBold ? boldClassName : []
                      }
                    }),
                    ...(goal
                      ? [
                          {
                            key: goalHeadingYTD,
                            value: formatRelativeNumber(ytdGoal),
                            keyClassNames: ['Bold'],
                            valueClassNames: ['Bold']
                          }
                        ]
                      : [])
                  ]
                : []
            }
          />
        </CardRow>
        <MainCard
          title="Total number of change-makers engaged"
          description="This chart shows the number of change-makers engaged to support people in the local neighborhoods through all the social impact initiatives developed / supported by Ingka Group (*co-workers, customers, and citizens), in the frame of Ingka/IKEA Neighborhoods."
          subtitle={
            <>
              <span>
                {formatAbsoluteNumber(sumBy(dataCurrFY, totalChangeMakers))} <span className="Label">people YTD</span>
              </span>
              {goal ? (
                <>
                  <span className="Label Spacer">•</span>
                  <span>
                    {formatAbsoluteNumber(ytdGoal)} <span className="Label">Goal YTD</span>
                  </span>
                </>
              ) : null}
            </>
          }
        >
          <div className="GraphContainer">
            <FinancialYearChartContainer
              generator={lineChart}
              series={series}
              domainFromSerie={currFyName}
              noData={isDataEmpty}
              lineChartConfiguration={{ startFromZero: true, focusStyle: 'none' }}
              testId="change-makers-main-chart"
            />
          </div>
        </MainCard>
        <ChangeMakersGraphSideBarCards changeMakers={dataCurrFY} currFyName={currFyName} />
      </div>
      <KpiPageLearnMoreModal
        lastUpdated={lastUpdated}
        modalState={modalState}
        onClose={() => setModalState({ isOpen: false })}
      />
      {benchmarks.length > 0 && (
        <BenchmarkingModal
          benchmarks={benchmarks}
          closeFn={() => setBenchmarkModalOpen(false)}
          footerBenchmark={countryBenchmark}
          headers={benchmarkingHeaders}
          isOpen={benchmarkModalOpen}
          locationId={locationId}
          renderBenchmark={getRenderBenchmark(isCompleteData, isFunctionAreaCentres)}
          sortBy="currentFyYtd"
          sortDirection="desc"
          title="people engaged"
        />
      )}
    </div>
  )
}
