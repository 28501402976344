import { Canvg } from 'canvg'
import pptxgen from 'pptxgenjs'
import { format, isSameDay } from 'date-fns'
import { capitalize, padStart } from 'lodash'
import { getIngkaFinancialYear } from '../components/Utils/dates'

import { Serie } from '../components/BaseGraphs/ChartContainer'
import { DateFormat } from '../components/BaseGraphs/GraphUtil'
import { analyticsEvent } from '../components/Utils/analytics'

type DomainFormat = 'MMM' | 'yyyy'

function getDomainFormat(dateFormat: DateFormat): DomainFormat {
  return dateFormat === 'fy' ? 'yyyy' : 'MMM'
}

async function createPNGFromGraph(chartContainer: Element | null) {
  const graph = chartContainer?.querySelector('svg.Chart')
  if (chartContainer == null || graph == null) {
    return undefined
  }
  const legendItems = Array.from(chartContainer.querySelectorAll('.LegendItems .Item'))
  const svg = new XMLSerializer().serializeToString(graph)
  const canvas = document.createElement('canvas')
  const width = chartContainer.clientWidth
  const height = graph.clientHeight
  canvas.width = width + 40
  const legendItemsPerRow = Math.floor(canvas.width / 160)
  canvas.height = height + 80 + 20 * Math.ceil(legendItems.length / legendItemsPerRow)
  const ctx = canvas.getContext('2d')
  if (ctx == null) {
    return
  }
  const v = await Canvg.fromString(ctx, svg)
  await v.render({ offsetX: 0, offsetY: 0, scaleWidth: width, scaleHeight: height })

  ctx.font = '14px "Noto Sans", sans-serif'
  const legendStartY = height + 80
  legendItems
    .map(l => {
      const icon = l.querySelector('.LegendIcon')
      const text = l.querySelector('.ItemText')?.textContent
      const disabled = l.querySelector('.Inactive')
      return { color: icon?.getAttribute('fill') ?? 'none', text, disabled: disabled != null }
    })
    .filter(legendItem => legendItem.disabled === false)
    .forEach((legendItem, i) => {
      const col = i % legendItemsPerRow
      const row = Math.floor(i / legendItemsPerRow)

      ctx.beginPath()
      ctx.fillStyle = legendItem.color
      ctx.arc(col * 140 + 20, row * 20 + legendStartY - 4, 7, 0, 2 * Math.PI)
      ctx.fill()
      ctx.fillStyle = 'black'
      ctx.fillText(legendItem.text ?? '', col * 140 + 36, row * 20 + legendStartY, 120)
    })

  const yAxisTitle = document.querySelector('.YAxisTitle')?.textContent
  if (yAxisTitle) {
    ctx.save()
    ctx.font = '10px "Noto Sans", sans-serif'
    ctx.translate(10, canvas.height / 2)
    ctx.rotate(-Math.PI / 2)
    ctx.textAlign = 'center'
    ctx.fillText(yAxisTitle, 0, 0)
    ctx.restore()
  }

  ctx.globalCompositeOperation = 'destination-over'
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  const canvasBlob: Blob = await new Promise(resolve =>
    canvas.toBlob(blob => resolve(blob ? blob : Promise.reject()), 'image/png')
  )
  return canvasBlob
}

export async function exportGraphAsPng(chartContainer: Element | null, name: string) {
  const pngBlob = await createPNGFromGraph(chartContainer)
  if (pngBlob === undefined) {
    return
  }
  analyticsEvent({
    category: 'Explore',
    action: 'Export',
    label: name
  })
  const url = URL.createObjectURL(pngBlob)
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = `${name}.png`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(url)
}

const ensureColorIsInHexformat = (color: string) => {
  const match = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/)
  if (match == null) {
    return color
  }
  return match.slice(1).reduce((acc, v) => acc + padStart(parseInt(v).toString(16), 2, '0'), '#')
}

export async function exportGraphAsPptx(
  heading: string,
  series: Serie[],
  domain: Date[],
  dateFormat: DateFormat,
  isLineChart: boolean,
  unit: string
) {
  analyticsEvent({
    category: 'Explore',
    action: 'Export',
    label: heading
  })
  const presentation = new pptxgen()
  presentation.layout = 'LAYOUT_WIDE'

  const chartSlide = presentation.addSlide()
  const chartData = series.map(serie => ({
    name: capitalize(serie.name),
    labels: domain.map(date => {
      if (dateFormat === 'fy') {
        date = getIngkaFinancialYear(date)
      }
      return format(date, getDomainFormat(dateFormat))
    }),
    values: domain.map(date => serie.data.find(d => isSameDay(new Date(d.x), date))?.y ?? 0),
    color: ensureColorIsInHexformat(serie.color)
  }))

  chartSlide.addText(`${heading}`, { x: '5%', y: '5%' })
  chartSlide.addChart(isLineChart ? presentation.ChartType.line : presentation.ChartType.bar, chartData, {
    w: '90%',
    h: '80%',
    x: '5%',
    y: '10%',
    barGrouping: 'stacked',
    chartColors: chartData.map(c => c.color),
    lineSmooth: true,
    showLegend: true,
    valAxisMinVal: 0,
    valAxisTitle: unit,
    showValAxisTitle: true
  })
  chartSlide.addText('Exported from 4P Dashboard', {
    hyperlink: { url: window.location.href, tooltip: 'View the graph in 4P Dashboard' },
    x: '72%',
    y: '95%',
    w: '25%',
    align: 'right',
    fontSize: 12,
    color: '000000'
  })

  const instructionsSlide = presentation.addSlide()
  instructionsSlide.addImage({
    path: '/ppt_instructions.png',
    x: '38%',
    y: '28%',
    w: '60%',
    h: '65%',
    sizing: { w: '60%', h: '65%', type: 'contain' }
  })
  instructionsSlide.addText('SHORT INSTRUCTIONS', {
    x: '5%',
    y: '12%',
    fontSize: 28,
    fontFace: 'Verdana Bold'
  })
  instructionsSlide.addText(
    'Export graph as a graph object in PowerPoint, which makes it editable and reusable in MS Office Tools. The data is available in Excel format (linked to the graph).',
    { x: '5%', y: '25%', w: '50%', fontSize: 16, fontFace: 'Verdana' }
  )
  instructionsSlide.addText(
    [
      { text: 'Quick guide to open the data in Excel' },
      {
        text: '\nClick the graph on the slide\nSelect Chart design from the top bar menu\nClick Edit data in Excel from the ribbon menu\nExcel opens and the attached data is available',
        options: { bullet: { type: 'number' } }
      }
    ],
    { x: '5%', y: '53%', w: '33%', fontSize: 16, fontFace: 'Verdana' }
  )

  presentation.writeFile({
    fileName: `${heading}.pptx`,
    compression: true
  })
}
